import apiClient from '@/services/axios'

export const getPatientData = async () => {
  const res = await apiClient.get('/tier')
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const getTierData = async () => {
  const res = await apiClient.get('/tier/master')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postTiering = async (idTierMaster, idTierPatient) => {
  const res = await apiClient.put(`/tier/${idTierPatient}`, { tier: idTierMaster })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postUser = async payload => {
  const res = await apiClient.post('/admin/users', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
