<template>
  <div class="row p-4 bg-white" style="border-radius: 24px;">
    <!-- Table -->
    <div
      :class="{
        'col-8': toggleDoc && !settings.isMobileView && !settings.isTabletView,
        'col-12': !toggleDoc || settings.isMobileView || settings.isTabletView,
      }"
    >
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="true"
        :scroll="{ x: 2000 }"
        style="background-color: #fff;border-radius: 12px"
        :row-class-name="record => (selectedRecord.key === record.key ? 'selected-row' : '')"
      >
        <template #name="{ record }">
          {{ record.patient.fullname }}
        </template>
        <template #action="{}">
          <div class="d-flex align-items-center justify-content-center">
            <p class="m-0">Edit</p>
            <EditOutlined class="ml-3" />
          </div>
        </template>
        <template #statusTiering="{ record }">
          <a-button
            type="button"
            class="btn  buttonHover"
            style="background-color: #F4FDB1;border: 1px solid #E6F37A;"
            disabled
            :style="{ width: !settings.isMobileView ? '177px' : '' }"
            v-if="record.statusTiering"
          >
            <div class="d-flex align-items-center justify-content-center">
              <CheckOutlined :class="{ 'mr-2': !settings.isMobileView }" class="buttonHover" />
              <p class="m-0 buttonHover" v-if="!settings.isMobileView">Terverifikasi</p>
            </div>
          </a-button>
          <button
            type="button"
            class="btn btn-outline-danger"
            style="background-color: #FFE2E2;"
            :style="{ width: !settings.isMobileView ? '177px' : '' }"
            v-else
            @click="selectRecord(record, 'financial')"
          >
            <div class="d-flex align-items-center justify-content-center">
              <ClockCircleOutlined :class="{ 'mr-2': !settings.isMobileView }" />
              <p class="m-0 text-left" v-if="!settings.isMobileView">Belum Terverifikasi</p>
            </div>
          </button>
        </template>
        <template #consent="{ record }">
          <button
            type="button"
            class="btn btn-outline-primary"
            :style="{ width: !settings.isMobileView ? '177px' : '' }"
            @click="selectRecord(record, 'consent')"
          >
            <div class="d-flex align-items-center justify-content-center" style="">
              <EyeOutlined :class="{ 'mr-2': !settings.isMobileView }" />
              <p class="m-0" v-if="!settings.isMobileView">Lihat</p>
            </div>
          </button>
        </template>
        <template #ktp="{ record, text }">
          <p class="m-0 hoverUnder" style="cursor: pointer;" @click="handleKtp(record)">
            {{ text }}
          </p>
        </template>
        <template #editHistory="{ record }">
          {{ record.editor }} <br />
          {{ record.editHistory }}
        </template>
        <template #golonganTier="{ record }">
          {{ record.master && record.master.tier ? `Tier ${record.master.tier}` : '-' }}
        </template>
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div style="padding: 8px">
            <a-input
              :maxlength="255"
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters, column.dataIndex)"
            >
              Reset
            </a-button>
          </div>
        </template>
        <template #filterIcon="{ filtered }">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
      </a-table>
    </div>
    <!-- RIGHT SECTION AT DEKSTOP -->
    <div class="col-4" v-if="toggleDoc && !settings.isMobileView && !settings.isTabletView">
      <div class="card p-3" style="border-radius: 8px; background-color: #EFEFEF;">
        <div class="card p-5">
          <h4 class="text-center">
            {{ sectionStatus === 'financial' ? 'Data Financial' : 'Data Consent' }}
          </h4>
          <a-button
            type="primary"
            style="width: 40px;"
            class="mb-2 d-flex align-items-center justify-content-center"
            @click="changeToggleModalPdf"
          >
            <EyeOutlined />
          </a-button>
          <div class="iframe-wrapper">
            <iframe
              :src="contoh"
              style="width: 100%;"
              frameborder="0"
              class="iframe-container"
              scrolling="no"
            ></iframe>
          </div>
        </div>
        <div class="card p-4" v-if="sectionStatus === 'financial'">
          <p class="text-center font-weight-bold">Pilih tier untuk pasien dengan data di atas</p>
          <div class="d-flex align-items-center justify-content-center">
            <button
              type="button"
              class="btn btn-outline-secondary"
              style="width: 100px;"
              @click="changeToggleModal(tierList[0])"
            >
              Tier {{ tierList[0].tier }}
            </button>
            <button
              type="button"
              class="btn btn-outline-warning mx-3"
              style="width: 100px;"
              @click="changeToggleModal(tierList[1])"
            >
              Tier {{ tierList[1].tier }}
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              style="width: 100px;"
              @click="changeToggleModal(tierList[2])"
            >
              Tier {{ tierList[2].tier }}
            </button>
          </div>
        </div>
        <button class="btn btn-primary" @click="toggleDoc = false">Close</button>
      </div>
      <a-modal
        v-model:visible="toggleModalPdf"
        centered
        class="text-center p-5"
        footer=""
        :closable="false"
        width="720px"
      >
        <iframe
          :src="contoh + '#toolbar=0'"
          style="width: 100%; "
          frameborder="0"
          class="iframe-modal-container"
          scrolling="no"
        ></iframe>
      </a-modal>
    </div>
    <!-- SECTION AT MOBILE -->
    <a-modal
      v-if="(toggleModalMobile && settings.isMobileView) || settings.isTabletView"
      v-model:visible="toggleModalMobile"
      centered
      footer=""
      :closable="false"
    >
      <div class="card p-3" style="border-radius: 8px; background-color: #EFEFEF;">
        <div class="card p-5">
          <h4 class="text-center">Data Financial</h4>
          <div class="iframe-wrapper" @click="changeToggleModalPdf">
            <iframe
              :src="contoh"
              style="width: 100%; "
              frameborder="0"
              class="iframe-container"
              scrolling="no"
            ></iframe>
            <div class="iframe-overlay"></div>
          </div>
        </div>
        <div class="card p-4">
          <p class="text-center font-weight-bold">Pilih tier untuk pasien dengan data di atas</p>
          <div class="d-flex align-items-center justify-content-center" v-if="tierList.length">
            <button
              type="button"
              class="btn btn-outline-secondary"
              style="width: 100px;"
              @click="changeToggleModal(tierList[0])"
            >
              Tier {{ tierList[0].tier }}
            </button>
            <button
              type="button"
              class="btn btn-outline-warning mx-3"
              style="width: 100px;"
              @click="changeToggleModal(tierList[1])"
            >
              Tier {{ tierList[1].tier }}
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              style="width: 100px;"
              @click="changeToggleModal(tierList[2])"
            >
              Tier {{ tierList[2].tier }}
            </button>
          </div>
        </div>
        <button class="btn btn-primary" @click="toggleModalMobile = false">Close</button>
      </div>
      <a-modal
        v-model:visible="toggleModalMobilePdf"
        centered
        class="text-center p-5"
        footer=""
        :closable="false"
        width="720px"
      >
        <iframe
          :src="contoh + '#toolbar=0'"
          style="width: 100%; "
          frameborder="0"
          class="iframe-modal-container"
          scrolling="no"
        ></iframe>
      </a-modal>
    </a-modal>
    <a-modal
      v-model:visible="toggleModal"
      centered
      class="text-center p-3"
      footer=""
      :closable="false"
      width="662px"
    >
      <h4 class="font-weight-bold">
        Apakah Anda yakin bahwa pasien dengan nama {{ selectedRecord.username }} berada di tier
        {{ tierValue }}
      </h4>
      <p class="my-4">
        Pasien dengan tier adalah pasien dengan penghasilan pajak tahunan antara 301-506 juta.
      </p>
      <div class="row">
        <a-button
          class="btn text-center mt-2 col-12 col-md-6"
          style="height: 48px; font-size: 18px;"
          @click="toggleModal = !toggleModal"
        >
          Tidak, Kembali
        </a-button>
        <a-button
          class="btn text-center mt-2 text-white col-12 col-md-6"
          style="height: 48px; font-size: 18px; background: linear-gradient(to left, #C76B1E 0%, #D58213 38%, #EDA702 100%);"
          @click="changeTier()"
        >
          Yakin, Konfirmasi
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="toggleKtp"
      centered
      class="text-center p-3"
      footer=""
      :closable="false"
      width="662px"
    >
      <img :src="ktp" alt="" srcset="" class=" w-100" />
      <div class="row mt-2 px-3">
        <a-button
          type="primary"
          class="btn text-center mt-2 col-12"
          style="height: 48px; font-size: 18px;"
          @click="toggleKtp = !toggleKtp"
        >
          Tutup
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue'
import {
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { Table, Modal, Button, notification } from 'ant-design-vue'
import contoh from '../../assets/pdf/contoh.pdf'
import { useStore } from 'vuex'
import { getPatientData, getTierData, postTiering } from '../../services/axios/anita'
export default {
  components: {
    CheckOutlined,
    ClockCircleOutlined,
    EditOutlined,
    SearchOutlined,
    'a-table': Table,
    EyeOutlined,
  },
  setup() {
    const data = ref([])
    const dummyData = ref([
      {
        pulihId: 9999999,
        key: 1,
        username: 'Alya',
        statusTiering: false,
        dot: '1',
        golonganTier: '',
        editor: 'Ahmad',
        editHistory: '24/04/2023',
        noKtp: '1234567890123456',
        email: 'alya@example.com',
      },
      {
        pulihId: 1223412,
        key: 2,
        username: 'Bayu',
        statusTiering: true,
        dot: '1',
        golonganTier: '0',
        editor: 'Abdul',
        editHistory: '24/04/2022',
        noKtp: '2345678901234567',
        email: 'bayu@example.com',
      },
      {
        pulihId: 1223412,
        key: 3,
        username: 'Citra',
        statusTiering: false,
        dot: '1',
        golonganTier: '',
        editor: 'Azis',
        editHistory: '24/04/2024',
        noKtp: '3456789012345678',
        email: 'citra@example.com',
      },
      {
        pulihId: 1223412,
        key: 4,
        username: 'Dian',
        statusTiering: true,
        dot: '2',
        golonganTier: '-2',
        editor: 'Faisal',
        editHistory: '24/04/2023',
        noKtp: '4567890123456789',
        email: 'dian@example.com',
      },
      {
        pulihId: 1223412,
        key: 5,
        username: 'Eko',
        statusTiering: false,
        dot: '3',
        golonganTier: '+1',
        editor: 'Bagus',
        editHistory: '24/04/2022',
        noKtp: '5678901234567890',
        email: 'eko@example.com',
      },
      {
        pulihId: 1223412,
        key: 6,
        username: 'Fina',
        statusTiering: true,
        dot: '4',
        golonganTier: '0',
        editor: 'Candra',
        editHistory: '24/04/2024',
        noKtp: '6789012345678901',
        email: 'fina@example.com',
      },
      {
        pulihId: 1223412,
        key: 7,
        username: 'Ghani',
        statusTiering: false,
        dot: '5',
        golonganTier: '-1',
        editor: 'Dewi',
        editHistory: '24/04/2023',
        noKtp: '7890123456789012',
        email: 'ghani@example.com',
      },
      {
        pulihId: 1223412,
        key: 8,
        username: 'Hilda',
        statusTiering: true,
        dot: '6',
        golonganTier: '',
        editor: 'Ella',
        editHistory: '24/04/2022',
        noKtp: '8901234567890123',
        email: 'hilda@example.com',
      },
      {
        pulihId: 1223412,
        key: 9,
        username: 'Ivan',
        statusTiering: false,
        dot: '7',
        golonganTier: '-2',
        editor: 'Firman',
        editHistory: '24/04/2024',
        noKtp: '9012345678901234',
        email: 'ivan@example.com',
      },
      {
        pulihId: 1223412,
        key: 10,
        username: 'Joko',
        statusTiering: true,
        dot: '8',
        golonganTier: '+1',
        editor: 'Gilang',
        editHistory: '24/04/2023',
        noKtp: '0123456789012345',
        email: 'joko@example.com',
      },
      {
        pulihId: 1223412,
        key: 11,
        username: 'Kania',
        statusTiering: false,
        dot: '9',
        golonganTier: '0',
        editor: 'Hasan',
        editHistory: '24/04/2022',
        noKtp: '1234509876543210',
        email: 'kania@example.com',
      },
      {
        pulihId: 1223412,
        key: 12,
        username: 'Lani',
        statusTiering: true,
        dot: '1',
        golonganTier: '-1',
        editor: 'Indah',
        editHistory: '24/04/2024',
        noKtp: '2345610987654321',
        email: 'lani@example.com',
      },
      {
        pulihId: 1223412,
        key: 13,
        username: 'Mira',
        statusTiering: false,
        dot: '2',
        golonganTier: '',
        editor: 'Jamil',
        editHistory: '24/04/2023',
        noKtp: '3456721098765432',
        email: 'mira@example.com',
      },
      {
        pulihId: 1223412,
        key: 14,
        username: 'Nino',
        statusTiering: true,
        dot: '3',
        golonganTier: '-2',
        editor: 'Karina',
        editHistory: '24/04/2022',
        noKtp: '4567832109876543',
        email: 'nino@example.com',
      },
      {
        pulihId: 1223412,
        key: 15,
        username: 'Oka',
        statusTiering: false,
        dot: '4',
        golonganTier: '+1',
        editor: 'Laras',
        editHistory: '24/04/2024',
        noKtp: '5678943210987654',
        email: 'oka@example.com',
      },
      {
        pulihId: 1223412,
        key: 16,
        username: 'Pia',
        statusTiering: true,
        dot: '5',
        golonganTier: '0',
        editor: 'Munir',
        editHistory: '24/04/2023',
        noKtp: '6789054321098765',
        email: 'pia@example.com',
      },
      {
        pulihId: 1223412,
        key: 17,
        username: 'Qiara',
        statusTiering: false,
        dot: '6',
        golonganTier: '-1',
        editor: 'Nurul',
        editHistory: '24/04/2022',
        noKtp: '7890165432109876',
        email: 'qiara@example.com',
      },
      {
        pulihId: 1223412,
        key: 18,
        username: 'Rais',
        statusTiering: true,
        dot: '7',
        golonganTier: '',
        editor: 'Omar',
        editHistory: '24/04/2024',
        noKtp: '8901276543210987',
        email: 'rais@example.com',
      },
      {
        pulihId: 1223412,
        key: 19,
        username: 'Sari',
        statusTiering: false,
        dot: '8',
        golonganTier: '-2',
        editor: 'Puspa',
        editHistory: '24/04/2023',
        noKtp: '9012387654321098',
        email: 'sari@example.com',
      },
      {
        pulihId: 1223412,
        key: 20,
        username: 'Tina',
        statusTiering: true,
        dot: '9',
        golonganTier: '+1',
        editor: 'Rizki',
        editHistory: '24/04/2022',
        noKtp: '0123498765432109',
        email: 'tina@example.com',
      },
      {
        pulihId: 1223412,
        key: 21,
        username: 'Umar',
        statusTiering: false,
        dot: '1',
        golonganTier: '0',
        editor: 'Ahmad',
        editHistory: '24/04/2024',
        noKtp: '1234567890123456',
        email: 'umar@example.com',
      },
      {
        pulihId: 1223412,
        key: 22,
        username: 'Vina',
        statusTiering: true,
        dot: '2',
        golonganTier: '-1',
        editor: 'Abdul',
        editHistory: '24/04/2023',
        noKtp: '2345678901234567',
        email: 'vina@example.com',
      },
      {
        pulihId: 1223412,
        key: 23,
        username: 'Wulan',
        statusTiering: false,
        dot: '3',
        golonganTier: '',
        editor: 'Azis',
        editHistory: '24/04/2022',
        noKtp: '3456789012345678',
        email: 'wulan@example.com',
      },
    ])

    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const selectedRowKeys = ref([])
    const toggleDoc = ref(false)
    const toggleModal = ref(false)
    const toggleModalMobile = ref(false)
    const toggleKtp = ref(false)
    const pdfUrl = ref('')
    const sectionStatus = ref('')
    const ktp = ref('')
    const handleKtp = record => {
      toggleKtp.value = !toggleKtp.value
      console.log(record, 'inirecord')
      // ktp.value = `https://pulih-api-prod.digitala.id ${record.patient.idPicture}`
    }
    const columns = [
      {
        title: 'Nama',
        dataIndex: 'patient.fullname',
        key: 'name',
        slots: { customRender: 'name', filterDropdown: 'filterDropdown', filterIcon: 'filterIcon' },
        align: 'left',
        width: settings.value.isMobileView ? 100 : '250',
        fixed: 'left',
        onFilter: (value, record) =>
          record.patient.fullName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      },
      {
        title: 'ID Pulih',
        dataIndex: 'patient.code',
        key: 'pulihId',
        slots: {
          customRender: 'pulihId',
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
        },
        align: 'left',
        width: settings.value.isMobileView ? 100 : '250',
        onFilter: (value, record) =>
          record.patient.code
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      },

      {
        title: 'Golongan Tier',
        dataIndex: 'master.tier',
        key: 'golonganTier',
        slots: { customRender: 'golonganTier' },
        align: 'center',
        filters: [
          {
            value: '-2',
            text: 'Tier -2',
          },
          {
            value: '-1',
            text: 'Tier -1',
          },
          {
            value: '0',
            text: 'Tier 1',
          },
          {
            value: '+1',
            text: 'Tier +1',
          },
        ],
        onFilter: (value, record) => record.master.tier.toString() === value,
      },
      {
        title: 'Dot',
        dataIndex: 'patient.programs.length',
        key: 'dot',
        slots: { customRender: 'dot' },
        align: 'center',
      },
      {
        title: 'Nomor KTP',
        dataIndex: 'patient.idNumber',
        key: 'noKtp',
        slots: { customRender: 'ktp' },
        align: 'center',
      },
      {
        title: 'Email',
        dataIndex: 'patient.email',
        key: 'email',
        // slots: { customRender: 'dot' },
        align: 'center',
      },
      // {
      //     title: 'Riwayat Edit',
      //     dataIndex: 'editHistory',

      //     key: 'editHistory',
      //     slots: { customRender: 'editHistory' },
      //     sorter: (a, b) => new Date(a.editHistory.split('/').reverse().join('-')) - new Date(b.editHistory.split('/').reverse().join('-')),
      // },
      {
        title: 'Consent',
        dataIndex: 'consent',
        key: 'consent',
        slots: { customRender: 'consent' },
        align: 'center',
      },
      {
        title: 'Status Tiering',
        dataIndex: 'statusTiering',
        key: 'statusTiering',
        slots: { customRender: 'statusTiering' },
        align: 'center',
        fixed: 'right',
        width: settings.value.isMobileView ? 100 : '200',
        filters: [
          {
            value: false,
            text: 'Belum Terverifikasi',
          },
          {
            value: true,
            text: 'Terverifikasi',
          },
        ],
        onFilter: (value, record) => record.statusTiering === value,
      },
    ]
    const tierList = ref('')
    const fetchData = async () => {
      const dataPasien = await getPatientData()
      const dataTier = await getTierData()
      const filteredData = dataTier.filter(item => item.tier !== '-2')

      // Sort the data by tier values in ascending order
      filteredData.sort((a, b) => a.tier.localeCompare(b.tier, undefined, { numeric: true }))
      tierList.value = filteredData
      // console.log(filteredData);
      // { pulihId: 1223412, key: 23, username: "Wulan", statusTiering: false, dot: '3', golonganTier: '', editor: "Azis", editHistory: "24/04/2022", noKtp: '3456789012345678', email: 'wulan@example.com' },
      // console.log(dataTier)
      const filteredDataPasien = dataPasien.map(e => {
        if (e.master) {
          e.statusTiering = true
        }
        return e
      })
      data.value = filteredDataPasien
      console.log(dataPasien, 'inioripasien')
      console.log(data.value)
    }
    onMounted(async () => {
      fetchData()
    })
    watch(toggleDoc, newVal => {
      console.log('toggleDoc changed:', newVal)
      if (!newVal) {
        console.log('Clearing selectedRecord due to toggleDoc change')
        selectedRecord.value = {} // Cek apakah ini yang menyebabkan loop
      }
    })

    const selectAndToggle = record => {
      selectedRowKeys.value = [record.key] // Select this row
      console.log(selectedRowKeys.value)
      if (toggleModalMobile.value) {
        console.log('initrue')
      }
      notification.info({
        message: 'Row Selected',
        description: `You have selected ${record.username}.`,
      })
    }
    const selectedRecord = ref({})
    const selectRecord = (record, status) => {
      if (settings.value.isMobileView || settings.value.isTabletView) {
        toggleModalMobile.value = !toggleModalMobile.value
      } else {
        toggleDoc.value = !toggleDoc.value // Toggle some UI element, e.g., document viewer
      }
      console.log(status, 'inistatus')
      sectionStatus.value = status
      selectedRecord.value = record // Store the selected record
      console.log(record, 'inirecordnya')
      console.log(selectedRecord.value, 'iniselectedrecord')
      notification.info({
        message: 'Pasien Telah Dipilih!',
        description: `Kamu telah memilih pasien ${record.username}.`,
      })
    }
    const tierValue = ref('')
    const changeToggleModal = tier => {
      tierValue.value = tier.tier
      toggleModal.value = !toggleModal.value
    }
    const getRowClassName = record => {
      return selectedRecord.value.key === record.key ? 'selected-row' : ''
    }
    const changeTier = async () => {
      selectedRecord.value.golonganTier = `${tierValue.value}` // Assuming you want to update the tier directly
      const idTier = tierList.value.filter(e => e.tier === tierValue.value)
      // console.log(selectedRecord.value.id, 'iniidmaster')
      // console.log(idTier[0].id, 'iniidtier')
      selectedRecord.value.statusTiering = true // Assuming you want to update the tier directly
      toggleModal.value = !toggleModal.value
      await postTiering(idTier[0].id, selectedRecord.value.id)
      fetchData()
      notification.success({
        message: 'Tier Updated',
        description: `Tier updated to Tier ${tierValue.value} for ${selectedRecord.value.patient.fullname}.`,
      })
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      searchText.value = selectedKeys[0]
      searchedColumn.value = dataIndex
    }
    const handleReset = (clearFilters, dataIndex) => {
      clearFilters()
      if (searchedColumn.value === dataIndex) {
        searchText.value = ''
      }
    }

    const toggleModalPdf = ref(false)
    const toggleModalMobilePdf = ref(false)
    const changeToggleModalPdf = () => {
      if (settings.value.isMobileView || settings.value.isTabletView) {
        toggleModalMobilePdf.value = !toggleModalMobilePdf.value
      } else {
        toggleModalPdf.value = !toggleModalPdf.value
      }
    }

    return {
      tierList,
      sectionStatus,
      toggleModalMobile,
      changeToggleModalPdf,
      toggleModalPdf,
      toggleModalMobilePdf,
      pdfUrl,
      handleReset,
      handleSearch,
      changeToggleModal,
      tierValue,
      selectedRecord,
      selectRecord,
      changeTier,
      data,
      columns,
      selectedRowKeys,
      toggleDoc,
      selectAndToggle,
      toggleModal,
      contoh,
      settings,
      getRowClassName,
      toggleKtp,
      ktp,
      handleKtp,
    }
  },
}
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  position: relative;
  width: 100%;
  /* Sesuaikan dengan kebutuhan */
  height: 330px;
  overflow: auto;
  /* Sesuaikan dengan kebutuhan */
}

.iframe-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.iframe-modal-container {
  height: 800px;
  overflow: hidden;
}

.iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  /* Bisa juga gunakan warna untuk mengaburkan */
  /* Pastikan lebih tinggi dari iframe */
  cursor: pointer;
}

iframe {
  overflow: hidden;
}

.btn.btn-outline-danger,
.show > .btn.btn-outline-danger {
  border-color: #ffbdbd;
}

.btn.btn-outline-primary:focus {
  background-color: #c75b7a !important;
}

.btn.btn-outline-primary:active {
  border: #c75b7a !important;
}

.btn.btn-outline-warning:hover {
  background-color: #808d1d !important;
}

.btn.btn-outline-warning:focus {
  background-color: #808d1d !important;
}

.btn.btn-outline-warning:active {
  border: #808d1d !important;
}

.buttonHover {
  color: #808d1d;
}

.buttonHover:hover {
  color: white !important;
}

.hoverUnder:hover {
  text-decoration: underline;
}

.btn.btn-outline-primary,
.show > .btn.btn-outline-primary {
  border-color: #8c3061;
  color: #8c3061;
}

:global(.ant-table-thead tr th) {
  background-color: white !important;
}

:global(.selected-row .ant-table-cell-fix-left) {
  background-color: #ffeef9;
}

:global(.selected-row .ant-table-cell-fix-right) {
  background-color: #ffeef9;
}

:global(.selected-row) {
  td {
    background-color: #ffeef9;
    /* Light blue background for selected rows */
  }
}
</style>
